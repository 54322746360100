<template>
  <div class="netboom-register">
    <div class="banner" />
    <div class="netboom-logo" />
    <div class="title">
      FREE to Play 200+ PC Games from US $4.9/month
    </div>
    <ul class="introduce-list">
      <li
        v-for="(item, index) in introduceList"
        :key="index"
      >
        <i
          class="icon"
          :style="{
            backgroundImage: `url(${item.icon})`
          }"
        />
        {{ item.words }}
      </li>
    </ul>
    <div class="netoom-data">
      <div class="star">
        <span class="main">
          4.1
          <span class="star-icon" />
        </span>
        <span class="name">110K Ratings</span>
      </div>
      <div class="download">
        <span class="main">
          10,000,000+
        </span>
        <span class="name">Downloads</span>
      </div>
    </div>

    <div class="swiper-area">
      <swiper
        class="swiper"
        :options="swiperOption"
      >
        <swiper-slide
          v-for="(item, index) in swiperList"
          :key="index"
        >
          <div class="swiper-items">
            <div
              class="cover"
              :style="{backgroundImage: `url(${item.url})`}"
            />
          </div>
        </swiper-slide>
        <div
          slot="pagination"
          class="swiper-pagination"
        />
      </swiper>
    </div>

    <div class="coyright">
      Copyright © 2020 BIFROST CLOUD PTE. LTD.
    </div>

    <div class="footer">
      <button
        class="register"
        @click="loginWidthGoogle"
      >
        Try Now
      </button>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css'
import { LOGIN_SOURCE } from '@/utils/enum.js'
import { login } from '@/api/user.js'
import {
  isAndroid,
  isIOS
} from '@/utils'
import firebase from 'firebase/app'
import 'firebase/auth'
import '@/utils/googleAnalytics.js'
const firebaseConfig = {
  apiKey: 'AIzaSyDjfaDGs9fBldBku5gjRv3zoEHitFJOl6U',
  authDomain: 'netboom.firebaseapp.com',
  databaseURL: 'https://netboom.firebaseio.com',
  projectId: 'netboom',
  storageBucket: 'netboom.appspot.com',
  messagingSenderId: '633656495394',
  appId: '1:633656495394:web:0d8afb1e942cbd7aac297b',
  measurementId: 'G-T7EXFRFNHM'
}

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig)
}

export default {
  components: { Swiper, SwiperSlide },
  data () {
    return {
      androidAppStoreAddress: 'https://play.google.com/store/apps/details?id=com.netboom.cloudgaming.vortex_stadia_shadow_GeForce',
      iosAppStoreAddress: 'itms-apps://itunes.apple.com/app/id1537225978',
      swiperOption: {
        pagination: {
          el: '.swiper-pagination'
        },
        autoplay: {
          delay: 3000
        },
        loop: true
      },
      introduceList: [
        {
          icon: require('@/assets/img/icon-1.png'),
          words: 'Transform your phone into a high-end gaming device.'
        },
        {
          icon: require('@/assets/img/icon-2.png'),
          words: 'Play your favorite PC games anywhere.'
        },
        {
          icon: require('@/assets/img/icon-3.png'),
          words: 'Play instantly! No games download.'
        },
        {
          icon: require('@/assets/img/icon-4.png'),
          words: 'New games added every week.'
        },
        {
          icon: require('@/assets/img/icon-5.png'),
          words: 'Low latency + 60FPS gaming experience.'
        }
      ],
      swiperList: [
        { url: require('@/assets/img/swiper-1.png') },
        { url: require('@/assets/img/swiper-2.png') },
        { url: require('@/assets/img/swiper-3.png') },
        { url: require('@/assets/img/swiper-4.png') }
      ]
    }
  },
  metaInfo: {
    title: 'NetBoom - Play PC Games On Your Phone',
    script: [
      { hid: 'google-analytics', src: 'https://www.googletagmanager.com/gtag/js?id=G-4JWFN1ENG5', async: '' }
    ],
    meta: [
      // { hid: 'keywords', name: 'keywords', content: '' },
      { hid: 'description', name: 'description', content: 'Netboom is a cloud gaming platform you can actually play on, PC games are hosted and streamed by Netboom. They’re playable instantly, on mobile and web, with no downloads required.' }
    ]
  },
  created () {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const { displayName, email, photoURL, uid } = user
        const options = {
          appId: 15,
          nickName: displayName,
          icon: photoURL,
          email,
          openId: uid,
          source: LOGIN_SOURCE.GOOGLE
        }
        const referrer = document.referrer
        const query = this.$route.query
        login({
          ...options,
          lastUrl: referrer,
          urlInfo: query
        })
          .then((res) => {
            if (res.code === 200) {
              firebase.auth().signOut().then(() => {
                if (isAndroid) {
                  window.location = this.androidAppStoreAddress
                } else if (isIOS) {
                  window.location = this.iosAppStoreAddress
                }
              })
            }
          })
      }
    })
  },
  methods: {
    loginWidthGoogle () {
      const provider = new firebase.auth.GoogleAuthProvider()
      firebase.auth().languageCode = this.$i18n.locale
      firebase.auth()
        .signInWithRedirect(provider)
    }
  }
}
</script>

<style lang="less">
  .netboom-register {
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 172px;
    background: t;
    .banner {
      height: 832px;
      background: url('~@/assets/img/Banner.webp') ~'100% / 100%' no-repeat,
      url('~@/assets/img/Banner_zoom.webp') ~'100% / 100%' no-repeat;
    }
    .netboom-logo {
      width: 330px;
      height: 85px;
      margin: 64px auto 57px;
      background: url('~@/assets/img/netboom-logo.png') ~'100% / 100%' no-repeat;
    }
    .title {
      width: 538px;
      height: 86px;
      font-size: 38px;
      text-align: center;
      margin: 0 auto;
      position: relative;
      line-height: normal;
      &::before,
      &::after {
        position: absolute;
        top: 22px;
        content: '';
        display: block;
        height: 5px;
        width: 71px;
      }
      &::before {
        left: -71px;
        background: linear-gradient(270deg, #FFCC37 0%, rgba(255, 204, 55, 0) 100%);
      }
      &::after {
        right: -71px;
        background: linear-gradient(90deg, #FFCC37 0%, rgba(255, 204, 55, 0) 100%);
      }
    }
    .introduce-list {
      padding: 64px 54px;
      box-sizing: border-box;
      > li {
        display: flex;
        margin-bottom: 33px;
        align-items: center;
        color: #666666;
        font-size: 27px;
        line-height: 32px;
        &:last-of-type {
          margin-bottom: 0;
        }
        .icon {
          display: block;
          width: 58px;
          height: 58px;
          margin-right: 21px;
          border-radius: 58px;
          background-size: cover;
          flex-shrink: 0;
        }
      }
    }
    .netoom-data {
      display: flex;
      height: 182px;
      border-top: 2px solid #F4F4F4;
      border-bottom: 2px solid #F4F4F4;
      color: #999999;
      position: relative;
      &::after {
        content: '';
        display: block;
        width: 2px;
        height: 146px;
        background: #F4F4F4;
        position: absolute;
        top: 19px;
        left: 50%;
      }
      .main {
        font-size: 44px;
        font-weight: bold;
        margin-bottom: 13px;
        .star-icon {
          display: inline-block;
          width: 29px;
          height: 29px;
          background: url('~@/assets/img/star.png') ~'100% / 100%' no-repeat;
        }
      }
      .name {
        font-size: 27px;
      }
      .star,
      .download {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }
    .swiper-area {
      width: 683px;
      height: 384px;
      border-radius: 8px;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
      margin: 63px auto 42px;
      position: relative;
      --swiper-pagination-color: #FFCC37;
      .swiper {
        width: 100%;
        height: 100%;
        .swiper-items {
          height: 100%;
          .cover {
            width: 100%;
            height: 100%;
            background-size: cover;
          }
        }
      }
      .swiper-pagination {
        position: absolute;
        bottom: 10px;
        z-index: 100;
      }
    }

    .coyright {
      font-size: 21px;
      color: #666666;
      box-sizing: border-box;
      padding: 0 34px;
    }
    .footer {
      position: fixed;
      width: 100%;
      height: 140px;
      left: 0;
      bottom: 0;
      padding: 10px 33px 0;
      background: white;
      z-index: 1000;
      .register {
        width: 683px;
        height: 92px;
        border: 0;
        outline: 0;
        background: linear-gradient(147deg, #FFD341 0%, #FFBB1D 100%);
        box-shadow: 0px 8px 13px #FFEBBB;
        border-radius: 8px;
        font-size: 29px;
        font-weight: bold;
      }
    }
  }
</style>
